import { PassportInDB, DataObjectInDB } from '@coac-gmbh/saifty-query-builder/dist/models'

export interface QueryBuilderManager extends PassportInDB {
  isSelected: boolean
}

export interface QueryComponentWithControls extends DataObjectInDB {
  isSelected: boolean
}

export enum QueryTypes {
  EHS = 'EHS',
  KVS = 'KVS',
}

export function getDefaultEHSComponentData() {
  return {
    name: '',
    operation: '',
    objects: [
      {
        name: '',
        aggregate: '',
        specification: {
          aggregate: '',
          system: [],
          identifier: [],
          rating: [],
          val_area: [],
          lang: [],
          attribute: [],
        },
      },
    ],
  }
}

export function getDefaultKVSComponentData() {
  return {
    name: '',
    operation: '',
    objects: [
      {
        name: '',
        aggregate: '',
        specification: {
          source: '',
          aggregate: '',
          attribute: [],
          dataType: [],
          rating: [],
          val_area: [],
          lang: [],
        },
      },
    ],
  }
}
